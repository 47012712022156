<template>
  <div>
    <a-modal
      v-model="modal1Visible"
      :footer="null"
      :title="null"
      :closable="true"
      centered
      :keyboard="true"
      :maskClosable="true"
      width="600"
    >
      <div v-if="modal1Visible">
        <div class="item">
          <div class="flex">
            <templateTitle title="我的申请记录"></templateTitle>
          </div>
          <el-table
            size="small"
            :data="listData"
            highlight-current-row
            style="width: 100%"
            :header-cell-style="{ color: '#212A33', backgroundColor: '#fafafa' }"
            border
            stripe
            height="550"
          >
            <el-table-column align="center" type="index" width="50" label="序号"> </el-table-column>
            <el-table-column align="center" prop="tenantName" label="企业名称"> </el-table-column>
            <el-table-column align="center" prop="createdTime" label="申请时间"> </el-table-column>
            <el-table-column prop="status" width="80" label="状态">
              <template slot-scope="scope">
                <div :class="['status' + scope.row.status]">{{ statusEnum[scope.row.status] }}</div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="reason" width="500" label="申请理由"> </el-table-column>
          </el-table>
          <!-- 分页组件 -->
          <Pagination  v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import templateTitle from '@/components/templateTitle.vue'
import { _usertenantRecordMypage } from '@/api/keepHome'
import Pagination from '@/components/Pagination'
export default {
  props: {},
  data() {
    return {
      modal1Visible: false,
      listData: [],
      title: '',
      type: '',
      loading: false,
      statusEnum: {
        0: '待审核',
        1: '通过',
        2: '拒绝'
      },
      // 分页参数
      pageparm: {
        currentPage: 1,
        pageSize: 10,
        total: null
      }
    }
  },
  created() {},
  components: {
    templateTitle,
    Pagination
  },
  methods: {
    // 分页插件事件
    callFather(parm) {
      this.pageparm.currentPage = parm.currentPage
      this.pageparm.pageSize = parm.pageSize
      this.usertenantRecordMypage()
    },
    // 获取我的申请记录
    usertenantRecordMypage() {
      const { currentPage, pageSize, total } = this.pageparm
      let params = {
        PageNo: currentPage,
        PageSize: pageSize
      }
      _usertenantRecordMypage(params).then(res => {
        if (res.code == 200) {
          this.listData = res.data.rows
          this.pageparm.total = res.data.totalRows
        } else {
        }
      })
    },
    add() {
      this.title = '添加'
      this.modal1Visible = true
      this.usertenantRecordMypage()
    },
    handleClose(done) {
      //   this.$refs['form'].resetFields()
      done()
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/common/scss/formDetails.scss';
.status0 {
  color: #ff8000;
}

// .bad {
.status1 {
  color: rgb(60, 170, 56);
}

.status2 {
  color: red;
}
</style>
